import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import { Box, Typography } from '@mui/material'

import { ProceduresHistoryStyles } from './index.css'
import LastProcedures from './molecules/lastProcedure';
import FrequentProcedures from './molecules/frequentProcedures';
import { contractPager } from '../../store/contract/contactListFilters';

const ProceduresHistory = () => {

    const { token } = useSelector(state => state.root.session.login.user);
    const { data: proceduresData } = useSelector(state => state?.root?.contract?.contractListFilters?.pagerData) || {};

    const [tabActive, setTabActive] = useState('frequentProcedure');
    const [data, setData] = useState([]);
    const dispatch = useDispatch();

    const styles = ProceduresHistoryStyles();

    class DataFormat {

        constructor() {
            this.data = [];
        };

        getData() {
            const sortedData = this.data.sort((a, b) => {
                return new Date(b.created) - new Date(a.created);
            })

            this.data = [...sortedData];
            return this.data;
        };

        addData(
            autoID,
            contractID,
            contractType,
            created,
            IContractTypeFeeID,
            iSignedCount,
            iSignerCount,
            processType,
            sPaymentStatus,
            status,
            tramitType
        ) {
            const newData = {
                autoID,
                contractID,
                contractType,
                created,
                IContractTypeFeeID,
                iSignedCount,
                iSignerCount,
                processType,
                sPaymentStatus,
                status,
                tramitType
            };

            this.data.push(newData);
        };
    };

    useEffect(() => {
        const dataFormat = new DataFormat();

        if (proceduresData?.length > 0) {
            const startIndex = proceduresData.length > 100 ? proceduresData.length - 100 : 0;
            const lastItems = proceduresData.slice(startIndex, proceduresData.length);

            lastItems.forEach(ele => {
                dataFormat.addData(
                    ele.autoId,
                    ele.sContractID,
                    ele.sTipoContrato,
                    ele.dContractDT,
                    ele.iContractTypeFeeID,
                    ele.iSignedCount,
                    ele.iSignerCount,
                    ele.sProcess,
                    ele.sPaymentStatus,
                    ele.sStatus,
                    ele.sTipoFirma
                );
            });
        }

        setData(dataFormat.getData());

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [proceduresData])

    useEffect(() => {
        dispatch(contractPager(1, 100, token));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Box className={styles.container}>

            <Box className={styles.tap_container}>

                <Box
                    className={tabActive !== 'frequentProcedure' ? `${styles.tap_box} ${styles.tap_box_inactive}` : styles.tap_box}
                    onClick={() => setTabActive('frequentProcedure')}
                >
                    <Typography className={styles.tap_text}>
                        Trámites frecuentes
                    </Typography>
                </Box>

                <Box
                    className={tabActive !== 'lastProcedures' ? `${styles.tap_box} ${styles.tap_box_inactive}` : styles.tap_box}
                    onClick={() => setTabActive('lastProcedures')}
                >
                    <Typography className={styles.tap_text}>
                        Últimos tramites realizados
                    </Typography>
                </Box>

            </Box>

            <Typography className={styles.mainText}>
                {tabActive === 'frequentProcedure' ? 'Trámites frecuentes' : 'Últimos trámites realizados'}
            </Typography>

            {tabActive === 'lastProcedures' && (
                <LastProcedures data={data} />
            )}

            {tabActive === 'frequentProcedure' && (
                <FrequentProcedures data={data} />
            )}
        </Box>
    )
}

export default ProceduresHistory