import {
    Fragment,
    useContext,
    useEffect,
    useRef,
    useState
} from "react";
import { useLocation } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import TableFooter from "@mui/material/TableFooter";
import OutlinedInput from "@mui/material/OutlinedInput";
import Grid from "@mui/material/Grid";
import FilledInput from "@mui/material/FilledInput";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Collapse from "@mui/material/Collapse";
import { default as AutocompleteMui } from "@mui/material/Autocomplete";

import { setearFecha, verifiVariable } from "../../../utils/enums";
import Rows from "./molecules/rows";
import TablePaginationActions from "./molecules/tablePagination";
import EnhancedTableHead from "./molecules/tableHead";
import { Context } from "../../../context/utilsContext";
import DeleteContractAlert from "./molecules/deleteContractAlert";
import { useDispatch, useSelector } from "react-redux";
import { contractPager, deleteFilters, setFilterList } from "../../../store/contract/contactListFilters";
import { theme } from "../../../theme/theme";
import Spinner from "../../spinners/Spinner";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const createData = (
    // 1697646314
    sProcess,
    sContractID,
    autoId,
    sContractName,
    tramit,
    sStatus,
    dContractDT,
    withDocument,
    iSignedCount
) => {
    const date = new Date(setearFecha(dContractDT));

    const unixTimestamp = Math.floor(date.getTime() / 1000);

    return {
        sProcess,
        dateFilter: unixTimestamp,
        autoIdFilter: parseInt(autoId.replace(/(ASN|AP)/g, "")),
        sContractID,
        action: "Acciones",
        autoId,
        sContractName,
        typeTramit: tramit["sTipoContrato"],
        tramit,
        sStatus,
        dContractDT,
        information: [
            {
                autoId,
                dContractDT,
                withDocument,
                iSignedCount,
            },
        ],
    };
};

const defaultValueFilterBy = "Filtrar por";

const GeneralListProced = ({ dataGrid, updateList, pagination = null }) => {
    const refInputControlTypeTramit = useRef();
    const { state } = useLocation();
    const { show } = useContext(Context);
    const [filterValue, setFilterValue] = useState("");
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("ID");
    const [rows, setRows] = useState([]);
    const [idContract, setIdContract] = useState("");
    const [openModalDelete, setOpenModalDelete] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [filterBy, setFilterBy] = useState();
    const [selectedFilter, setSelectedFilter] = useState({});
    const [sProcess, setSProcess] = useState("");
    const [autoCompleteValue, setAutoCompleteValue] = useState("");
    const { user } = useSelector((state) => state.root.session.login);
    const {
        tramites: {
            contracTypesNames,
            // allTramitAgreements
        },
        contractListFilters: {
            filterBy: filterByState,
            filters,
            pagerData,
            loading,
            filterListData
        }
    } = useSelector(state => state.root.contract);
    const dispatch = useDispatch();

    const handleFiltersInitialValue = () => {
        if (filterByState) {
            let filter = {};

            if (filters === "sPaymentStatus") {
                filter = { payment_status: "PENDING" };
            } else {
                filter = { contract_status: filters };
            }

            const { pagination: { perPage } } = pagerData;
            const { token } = user;

            if (!verifiVariable(state) && !verifiVariable(state.list) && ["EXPRESS", "NORMAL"].includes(state.list)) filter = { ...filter, process_type: state.list };
            if (!verifiVariable(state) && !verifiVariable(state.list) && state.list === "SIGNATURE_ANF") filter = { ...filter, signature_modal: state.list };

            dispatch(contractPager(1, perPage, token, filter));

            dispatch(setFilterList(filter));

            setFilterBy(filterByState);
            setFilterValue(filters === "sPaymentStatus" ? "PENDING" : filters);
        };
    };

    const setDataFilter = () => {
        if (Object.keys(selectedFilter).length > 0) {

            let filter = { ...selectedFilter };
            const { pagination: { perPage } } = pagerData;
            const { token } = user;

            if (!verifiVariable(state) && !verifiVariable(state.list) && ["EXPRESS", "NORMAL"].includes(state.list)) filter = { ...filter, process_type: state.list };
            if (!verifiVariable(state) && !verifiVariable(state.list) && state.list === "SIGNATURE_ANF") filter = { ...filter, signature_modal: state.list };

            dispatch(contractPager(1, perPage, token, filter));

            dispatch(setFilterList(filter));
        }
    }

    const handleFilterInputChange = (event) => {
        setFilterValue(event.target.value);
    };

    const handleFilterSelectStatusChange = (event) => {

        let filter = {};

        if (filterBy === "sStatus") {
            filter = { contract_status: event.target.value };
        } else if (filterBy === "sPaymentStatus") {
            filter = { payment_status: event.target.value };
        } else {
            filter = { signature_modal: event.target.value };
        }

        const { pagination: { perPage } } = pagerData;
        const { token } = user;

        if (!verifiVariable(state) && !verifiVariable(state.list) && ["EXPRESS", "NORMAL"].includes(state.list)) filter = { ...filter, process_type: state.list };
        if (!verifiVariable(state) && !verifiVariable(state.list) && state.list === "SIGNATURE_ANF") filter = { ...filter, signature_modal: state.list };

        dispatch(contractPager(1, perPage, token, filter));

        dispatch(setFilterList(filter));

        setFilterValue(event.target.value);
    };

    const handleChange = (event) => {
        setFilterBy(event.target.value);
        // if(event.target.value !== "sStatus")
        setFilterValue("");
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    // eslint-disable-next-line
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    // eslint-disable-next-line
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        // setPage(0);

        const { token } = user;

        if (![verifiVariable(token), verifiVariable(pagerData)].includes(true)) {
            // eslint-disable-next-line
            const { pagination: { currentPage } } = pagerData;
            dispatch(contractPager(1, parseInt(event.target.value, 10), token, filterListData));
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    // eslint-disable-next-line
    const stableSort = (array, comparator) => {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }

            return a[1] - b[1];
        });

        return stabilizedThis.map((el) => el[0]);
    };

    const descendingComparator = (a, b, orderBy) => {

        const valueA = a[orderBy] || 'No especificado';
        const valueB = b[orderBy] || 'No especificado';

        if (valueB < valueA) {
            return -1;
        };
        if (valueB > valueA) {
            return 1;
        };
        return 0;
    };

    // eslint-disable-next-line
    const getComparator = (order, orderBy) => {
        let pattern = "";

        if (orderBy === "Tramite") {
            pattern = "typeTramit";
        } else if (orderBy === "ID") {
            pattern = "autoIdFilter";
        } else if (orderBy === "Fecha") {
            pattern = "dateFilter";
        } else if (orderBy === "Nombre del contrato") {
            pattern = "sContractName";
        } else {
            pattern = "Acciones";
        };

        return order === "desc"
            ? (a, b) => descendingComparator(a, b, pattern)
            : (a, b) => -descendingComparator(a, b, pattern);
    };

    const getOptionLabelTypeTramit = (option) => {
        if (option.trim && option.trim() === "") {
            return "";
        }
        return option.name;
    };

    const FilterSelectTypeTramits = () => {
        return (
            <FormControl sx={{ m: 1 }} fullWidth>
                <AutocompleteMui
                    value={autoCompleteValue}
                    isOptionEqualToValue={(option, value) => option.name === value.name}
                    multiple={false}
                    options={!verifiVariable(contracTypesNames) && contracTypesNames.length > 0 ? contracTypesNames : []}
                    getOptionLabel={getOptionLabelTypeTramit}
                    onChange={(e, options, reason) => {

                        if (!verifiVariable(options)) {
                            let filter = { contract_type_name: options["name"] };

                            const { pagination: { perPage } } = pagerData;
                            const { token } = user;

                            if (!verifiVariable(state) && !verifiVariable(state.list) && state.list !== "SIGNATURE_ANF") filter = { ...filter, process_type: state.list };

                            if (!verifiVariable(state) && !verifiVariable(state.list) && state.list === "SIGNATURE_ANF") filter = { ...filter, signature_modal: state.list };

                            dispatch(contractPager(1, perPage, token, filter));

                            dispatch(setFilterList(filter));

                            setFilterValue(options["name"]);

                            setAutoCompleteValue(options);
                        } else {
                            setFilterValue("");
                        }



                    }}
                    renderInput={(params) => (
                        <TextField
                            ref={refInputControlTypeTramit}
                            {...params}
                            placeholder="Seleccione el tipo de trámite"
                        />
                    )}
                />
            </FormControl>
        );
    };

    const FilterSelectStatus = () => {
        return (
            <FormControl sx={{ m: 1 }} fullWidth>
                <InputLabel id="demo-simple-select-autowidth-label-1">
                    Estados
                </InputLabel>
                <Select
                    disabled={filterBy === defaultValueFilterBy}
                    value={filterValue}
                    onChange={handleFilterSelectStatusChange}
                    autoWidth
                    MenuProps={MenuProps}
                    input={<OutlinedInput label="Estados" />}
                >
                    <MenuItem disabled={filterBy !== "sStatus"} value={"CREATED"}>Creados</MenuItem>
                    <MenuItem disabled={filterBy !== "sStatus"} value={"REVIEW"}>En revisión</MenuItem>
                    <MenuItem disabled={filterBy !== "sStatus"} value={"REJECTED"}>Rechazados</MenuItem>
                    <MenuItem disabled={filterBy !== "sPaymentStatus"} value={"PENDING"}>Pendientes de pago</MenuItem>
                    <MenuItem disabled={filterBy !== "sPaymentStatus"} value={"PARTIALLY_PAID"}>Parcialmente pagados</MenuItem>
                    <MenuItem disabled={filterBy !== "sPaymentStatus"} value={"PAIDOUT"}>Pagados</MenuItem>
                    <MenuItem disabled={filterBy !== "sStatus"} value={"IN_NOTARY"}>En notaría</MenuItem>
                    <MenuItem disabled={filterBy !== "sStatus"} value={"SIGNED_PENDING"}>Pendientes de firma</MenuItem>
                    <MenuItem disabled={filterBy !== "sStatus"} value={"FINISHED"}>Entregados</MenuItem>
                    <MenuItem disabled={filterBy !== "sTipoFirma"} value={"SIGNATURE"}>Firma simple</MenuItem>
                    <MenuItem disabled={filterBy !== "sTipoFirma"} value={"SIGNATURE_CERT_NOTARIAL"}>Certificación notarial</MenuItem>
                    <MenuItem disabled={filterBy !== "sTipoFirma"} value={"SIGNATURE_PROT_NOTARIAL"}>Protocolización notarial</MenuItem>

                </Select>
            </FormControl>
        );
    };

    useEffect(() => {

        if (filterBy === "autoId") setSelectedFilter({ ap_number: filterValue });

        if (filterBy === "sTipoContrato") setSelectedFilter({ contract_type_name: filterValue });

        if (filterBy === "sContractName") setSelectedFilter({ contract_name: filterValue });

        if (filterBy === "sStatus") setSelectedFilter({ contract_status: filterValue });

        if (filterBy === "sPaymentStatus") setSelectedFilter({ payment_status: filterValue });


        if (filterBy === "dContractDT") {
            setSelectedFilter({
                date: {
                    beginDate: filterValue,
                    endDate: filterValue
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValue, filterBy]);

    useEffect(() => {
        if (!verifiVariable(dataGrid) && dataGrid.length > 0) {
            const arrayData = [];

            dataGrid.forEach((element) => {
                arrayData.push(
                    createData(
                        element["sProcess"],
                        element["sContractID"],
                        element["autoId"],
                        !verifiVariable(element["sContractName"]) ? element["sContractName"] : 'No especificado',
                        {
                            sTipoContrato: element["sTipoContrato"],
                            sTipoFirma: element["sTipoFirma"],
                        },
                        {
                            sStatus: element["sStatus"],
                            sPaymentStatus: element["sPaymentStatus"],
                        },
                        element["dContractDT"],
                        element["withDocument"],
                        element["iSignedCount"]
                    )
                );
            });

            setRows(arrayData);
        } else {
            setRows([]);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    useEffect(() => {
        handleFiltersInitialValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterByState, filters]);

    useEffect(() => {
        return () => {
            dispatch(deleteFilters());
        };
    }, [dispatch]);

    return (
        <Fragment>
            <DeleteContractAlert
                updateData={() => updateList()}
                sProcess={sProcess}
                contractID={idContract}
                openModalDelete={openModalDelete}
            />

            <Collapse in={show} orientation="vertical">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <FormControl sx={{ m: 1 }} fullWidth>
                            <InputLabel id="demo-simple-select-autowidth-label">
                                Filtrar por
                            </InputLabel>
                            <Select
                                input={<OutlinedInput label="Filtrar por" />}
                                labelId="demo-simple-select-autowidth-label"
                                id="demo-simple-select-autowidth"
                                value={filterBy}
                                onChange={handleChange}
                                autoWidth
                                MenuProps={MenuProps}
                            >
                                <MenuItem value={"autoId"}>ID</MenuItem>
                                <MenuItem value={"sContractName"}>Nombre del contrato</MenuItem>
                                <MenuItem value={"sTipoContrato"}>Tipo trámite</MenuItem>
                                <MenuItem disabled={!verifiVariable(state) && !verifiVariable(state.list) && state.list === "SIGNATURE_ANF"} value={"sTipoFirma"}>Tipo firma</MenuItem>
                                <MenuItem value={"dContractDT"}>Fecha</MenuItem>
                                <MenuItem value={"sStatus"}>Estado de trámite</MenuItem>
                                <MenuItem value={"sPaymentStatus"}>Estado de pago</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        {
                            filterBy === "sTipoContrato" &&
                            <FilterSelectTypeTramits />
                        }

                        {
                            [
                                "dContractDT",
                                "autoId",
                                "sContractName"
                            ].includes(filterBy) && (
                                <FormControl sx={{ m: 1 }} fullWidth>
                                    <FilledInput
                                        disabled={filterBy === defaultValueFilterBy}
                                        placeholder="Buscar"
                                        value={filterValue}
                                        onChange={handleFilterInputChange}
                                        endAdornment={
                                            <InputAdornment
                                                position="end"
                                            >
                                                <IconButton
                                                    onClick={() => setDataFilter()}
                                                    edge="end"
                                                >
                                                    <SearchIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </FormControl>
                            )
                        }

                        {
                            [
                                "sStatus",
                                "sPaymentStatus",
                                "sSignedStatus",
                                "sTipoFirma"
                            ].includes(filterBy) &&
                            <FilterSelectStatus />
                        }
                    </Grid>
                </Grid>
            </Collapse>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>

                <TableContainer
                    sx={{
                        [theme.breakpoints.only("xs")]: {
                            maxWidth: 320
                        }
                    }}
                >
                    <Table stickyHeader aria-label="sticky table">
                        <EnhancedTableHead
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                        />

                        <TableBody>

                            {
                                loading &&
                                (<Spinner loading={true} type="bar" opacity={true} />)
                            }

                            {!loading && rows.map((row) => (
                                <Rows
                                    sProcess={sProcess}
                                    key={row["autoId"]}
                                    deleteContrat={(e) => {
                                        setOpenModalDelete((value) => 1 + value);
                                        setIdContract(e["sContractID"]);
                                        setSProcess(e["sProcess"]);
                                    }}
                                    row={row}
                                />
                            ))}
                        </TableBody>

                        <TableFooter>
                            <TableRow>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        5,
                                        10,
                                        25/* ,
                                        { label: "Todos", value: -1 }, */
                                    ]}
                                    // colSpan={3}
                                    // count={countRows}
                                    // count={!verifiVariable(pagination) ? pagination.totalRows : 0}
                                    // rowsPerPage={rowsPerPage}
                                    // rowsPerPage={!verifiVariable(pagination) ? pagination.perPage : 0}
                                    // page={!verifiVariable(pagination) ? pagination.currentPage : 0}
                                    // page={page}
                                    SelectProps={{
                                        inputProps: {
                                            "aria-label": "filas por página",
                                        },
                                        native: true,
                                    }}
                                    labelRowsPerPage={"Trámites por página"}
                                    // onPageChange={handleChangePage}
                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                    ActionsComponent={TablePaginationActions}
                                    labelDisplayedRows={({ from, to, count }) => {
                                        if (!verifiVariable(pagination)) {
                                            return `${pagination.currentPage} de ${pagination.totalPages} total ${pagination.totalRows} trámites`;
                                        } else {
                                            return "";
                                        }
                                        // return `${from}–${to} de ${count !== -1 ? count : `más de ${to}`}`;
                                    }}
                                />
                            </TableRow>
                        </TableFooter>
                    </Table>
                </TableContainer>
            </Paper>


        </Fragment>
    );
};

export default GeneralListProced;